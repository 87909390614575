<template>
  <div>
    <b-overlay
        :show="show"
        no-wrap
    />
    <div v-if="!check_exit">


      <div class="p-2">
        <b-row>
          <b-col cols="6">
            <h2>Cita de: {{ partner.name }}</h2>
            <div style="margin-top: 10px;display: flex">
              <b-button
                  @click="changeStatusAppointment(id_appoinment, 'pending')"
                  v-if="appointment.appoint_state === 'new' || appointment.appoint_state === 'rejected'  || appointment.appoint_state === 'approved'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mr-1"
              >
                {{ appointment.appoint_state === 'new' ? 'Pendiente' : 'Reiniciar' }}
              </b-button>
              <b-button
                  @click="changeStatusAppointment(id_appoinment, 'approved')"
                  v-if="appointment.appoint_state === 'new' || appointment.appoint_state === 'pending'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mr-1"
              >
                Aprobar
              </b-button>
              <b-button
                  @click="changeStatusAppointment(id_appoinment, 'rejected')"
                  v-if="appointment.appoint_state === 'new' || appointment.appoint_state === 'pending'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mr-1"
              >
                Rechazar
              </b-button>
            </div>
          </b-col>
          <b-col cols="6">
            <h2 style="margin-top: 20px">Número de cita:
              <strong>{{ appointment.name }}</strong></h2>

            <h2 style="margin-top: 20px">Estado de la cita:
              <strong>{{ changeTextSpanishSingle(appointment.appoint_state) }}</strong></h2>

          </b-col>
        </b-row>

      </div>
      <div class="p-2" align="center" v-if="place_active">
        <b-button

            @click="verifyState(appointment.id)"
            style="width: 200px"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            class="mr-1"
        >
          Check Salida
        </b-button>
      </div>

      <b-form

          class="p-2"
          @submit.prevent="createAppointment('edit')"

      >
        <div v-if="CheckAppointment">
          <b-row>
            <b-col cols="2">

            </b-col>
            <b-col cols="2">
              <b-form-checkbox
                  class="custom-control-primary"
                  v-model="appointment.CheckAppointment.check_quoted"
                  :disabled="appointment.CheckAppointment.check_quoted"
              >
                Cotizado
              </b-form-checkbox>
              <p v-if="appointment.CheckAppointment.date_quoted">
                {{ this.resHoursOdoo(appointment.CheckAppointment.date_quoted) }}</p>
              <p v-if="appointment.CheckAppointment.HrEmployee">
                {{ appointment.CheckAppointment.HrEmployee.name }}</p>
            </b-col>
            <b-col cols="2">
              <b-form-checkbox
                  class="custom-control-primary"
                  v-model="appointment.CheckAppointment.check_assortment_pharmacy"
                  :disabled="appointment.CheckAppointment.check_assortment_pharmacy"
              >
                Surtido Farmacia
              </b-form-checkbox>
              <p v-if="appointment.CheckAppointment.date_assortment_pharmacy">
                {{ this.resHoursOdoo(appointment.CheckAppointment.date_assortment_pharmacy) }}</p>
              <p v-if="appointment.CheckAppointment.HrEmployee2">
                {{ appointment.CheckAppointment.HrEmployee2.name }}</p>
            </b-col>
            <b-col cols="2">
              <b-form-checkbox
                  class="custom-control-primary"
                  v-model="appointment.CheckAppointment.check_confirmed_patient"
                  :disabled="true"
              >
                Paciente Confirmado
              </b-form-checkbox>
              <p v-if="appointment.CheckAppointment.date_confirmed_patient">
                {{ this.resHoursOdoo(appointment.CheckAppointment.date_confirmed_patient) }}</p>
              <p v-if="appointment.CheckAppointment.HrEmployee3">
                {{ appointment.CheckAppointment.HrEmployee3.name }}</p>
            </b-col>
            <b-col cols="2">
              <b-form-checkbox
                  class="custom-control-primary"
                  v-model="appointment.CheckAppointment.check_in"
                  :disabled="appointment.CheckAppointment.check_in"
              >
                Check In
              </b-form-checkbox>
              <p v-if="appointment.CheckAppointment.date_check_in">
                {{ this.resHoursOdoo(appointment.CheckAppointment.date_check_in) }}</p>
              <p v-if="appointment.CheckAppointment.HrEmployee4">
                {{ appointment.CheckAppointment.HrEmployee4.name }}</p>
            </b-col>
            <b-col cols="2">

            </b-col>
          </b-row>


        </div>

        <b-form-group
            v-if="verified_treatment_medicals"
            label="Cita con"
            label-for="h-email"
        >
          <v-select
              @input="groupsVerify()"
              v-model="select_group"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="value"
              :options="groups"
          />
        </b-form-group>


        <b-form-group
            :label="place_active ? 'Salas' : 'Medicos'"
            label-for="h-email"
        >
          <v-select

              @input="medicalVerify()"
              v-model="select_medical"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="value"
              :options="medicals"
          />
        </b-form-group>
        <b-form-group label="Motivo de cita" label-for="h-email">
          <v-select
              v-model="reason_appoinment"
              @input="changeReason()"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="value"
              :options="reasons"
          />
        </b-form-group>
        <b-form-group
            v-if="!place_active"
            label="Opciones cita"
            label-for="h-email"
        >
          <v-select
              @input="typesVerify()"
              v-model="select_types"
              label="value"
              :options="types"
          />
        </b-form-group>
        <b-form-group
            label-for="start-date"
        >
          <label for=""><span style="margin-left: 20px">Hora de inicio</span> <span style="margin-left: 20px">~</span>
            <span style="margin-left: 20px">Hora de salida</span></label>
          <date-picker
              v-model="value2"
              :disabled="checkCompleteStatus"
              format="YYYY-MM-DD HH:mm"
              type="datetime"
              :show-time-header="true"
              @close="changeDate()"
              style="width: 100%"
              :disabled-date="(date) => date <= disabledBefore"
              range
              confirm
              placeholder="Seleccione las fechas"
          >
            <template #header>
              <div style="text-align: left">
                <b-row>
                  <b-col cols="6">
                    <h3 style="text-align: center"> Inicio</h3>

                  </b-col>
                  <b-col cols="6">
                    <h3 style="text-align: center">Fin</h3>

                  </b-col>
                </b-row>

              </div>
            </template>
          </date-picker>
        </b-form-group>


        <!--      <b-form-group
                  label="Proceso Clinica"
              >
                <b-form-select
                    v-model="appointment.appoint_state_clinical"
                    :options="clinical_states"
                />
              </b-form-group>-->
        <b-form-group
            label="Ciclo"
        >
          <b-form-input
              @keypress="isNumber($event)"
              v-model="appointment.cycle"
              placeholder="Ciclo"
          />
        </b-form-group>
        <label for="textarea-default">Notas</label>
        <b-form-textarea
            v-model="appointment.description"
            id="textarea-default1"
            placeholder="Notas"
            rows="3"
        />
        <b-row class="mt-3">
          <b-col cols="6" v-if="new_file">
            <b-form-group
                v-if="!verified_treatment"
                label="Subir Archivo"
                label-for="h-email"
            >
              <b-form-file

                  @change="handleImage(1)"
                  id="file1"
                  placeholder="Subir archivo."
                  drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="new_file2">
            <b-form-group
                v-if="!verified_treatment2"
                label="Subir Archivo"
                label-for="h-email"
            >
              <b-form-file

                  @change="handleImage(2)"
                  id="file2"
                  placeholder="Subir archivo."
                  drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="new_file3">
            <b-form-group
                v-if="!verified_treatment3"
                label="Subir Archivo"
                label-for="h-email"
            >
              <b-form-file

                  @change="handleImage(3)"
                  id="file3"
                  placeholder="Subir archivo."
                  drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="new_file4">
            <b-form-group
                v-if="!verified_treatment4"
                label="Subir Archivo"
                label-for="h-email"
            >
              <b-form-file

                  @change="handleImage(4)"
                  id="file4"
                  placeholder="Subir archivo."
                  drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
          </b-col>
          <!-- <b-col cols="2">
            <b-form-checkbox
                @change="medicalVerifyPaxman()"
                v-model="appointment.paxman"
                :value="appointment.paxman ? true : ''"
            >
              Paxman
            </b-form-checkbox>
          </b-col>
          <b-col cols="2">
            <b-form-checkbox
              v-model="appointment.pago_directo"
              :value="appointment.pago_directo ? true : ''"
            >
              Pago Directo
            </b-form-checkbox>
          </b-col>
          <b-col cols="1">
            <b-form-checkbox
                v-model="appointment.reiki"
                :value="appointment.reiki ? true : ''"
            >
              Reiki
            </b-form-checkbox>
          </b-col>
          <b-col cols="3">
            <b-form-checkbox
                v-model="appointment.functional_therapy"
                :value="appointment.functional_therapy ? true : ''"
            >
              Terapia Funcional
            </b-form-checkbox>
          </b-col> -->
        </b-row>
        <b-button
            class="mt-1"
            v-if="available_appointent"
            v-ripple.400="'rgba(25, 17, 46, 0.15)'"
            type="submit"
            variant="outline-secondary"
        >
          Guardar Cambios
        </b-button>
        <br>
        <div>
          <b-row class="mt-3">
            <b-col cols="4" v-if="verified_treatment" class="mt-1">
              <a @click="downloads(appointment.id, appointment.treatment_file, appointment.appoint_date,appointment.treatment_file_txt,partner.name, 1,false)"
                 style="text-align: center">
                <feather-icon icon="SearchIcon" style="color: green" size="20"/>
                Visualizar Archivo</a> <br>
              <a @click="deleteFileAppointment(appointment.id,1)" style="text-align: right">
                <feather-icon icon="TrashIcon" style="color: darkred" size="20"/>
              </a>
              <a @click="downloads(appointment.id, appointment.treatment_file, appointment.appoint_date,appointment.treatment_file_txt,partner.name, 1, true)"
                 style="text-align: center">
                <feather-icon icon="FolderIcon" size="100"/>
                <br> <span style="text-align: center">{{ appointment.treatment_file_txt }} </span>
                <br>
                <feather-icon icon="DownloadIcon" style="color: black" size="20"/>
                Descargar Archivo </a>
            </b-col>
            <b-col cols="4" v-if="verified_treatment2" class="mt-1">
              <a @click="downloads(appointment.id, appointment.treatment_file2, appointment.appoint_date,appointment.treatment_file_txt2,partner.name, 2, false)"
                 style="text-align: center">
                <feather-icon icon="SearchIcon" style="color: green" size="20"/>
                Visualizar Archivo</a> <br>
              <a @click="deleteFileAppointment(appointment.id,2)" style="text-align: right">
                <feather-icon icon="TrashIcon" style="color: darkred" size="20"/>
              </a>
              <a @click="downloads(appointment.id, appointment.treatment_file2, appointment.appoint_date,appointment.treatment_file_txt2,partner.name, 2, true)"
                 style="text-align: center">
                <feather-icon icon="FolderIcon" size="100"/>
                <br> <span style="text-align: center">{{ appointment.treatment_file_txt2 }} </span>
                <br>
                <feather-icon icon="DownloadIcon" style="color: black" size="20"/>
                Descargar Archivo </a>
            </b-col>
            <b-col cols="4" v-if="verified_treatment3" class="mt-1">
              <a @click="downloads(appointment.id, appointment.treatment_file3, appointment.appoint_date,appointment.treatment_file_txt3,partner.name, 3, false)"
                 style="text-align: center">
                <feather-icon icon="SearchIcon" style="color: green" size="20"/>
                Visualizar Archivo</a> <br>
              <a @click="deleteFileAppointment(appointment.id,3)" style="text-align: right">
                <feather-icon icon="TrashIcon" style="color: darkred" size="20"/>
              </a>
              <a @click="downloads(appointment.id, appointment.treatment_file3, appointment.appoint_date,appointment.treatment_file_txt3,partner.name, 3, true)"
                 style="text-align: center">
                <feather-icon icon="FolderIcon" size="100"/>
                <br> <span style="text-align: center">{{ appointment.treatment_file_txt3 }} </span>
                <br>
                <feather-icon icon="DownloadIcon" style="color: black" size="20"/>
                Descargar Archivo </a>
            </b-col>
            <b-col cols="4" v-if="verified_treatment4" class="mt-1">
              <a @click="downloads(appointment.id, appointment.treatment_file4, appointment.appoint_date,appointment.treatment_file_txt4,partner.name, 4, true)"
                 style="text-align: center">
                <feather-icon icon="SearchIcon" style="color: green" size="20"/>
                Visualizar Archivo</a> <br>
              <a @click="deleteFileAppointment(appointment.id,4)" style="text-align: right">
                <feather-icon icon="TrashIcon" style="color: darkred" size="20"/>
              </a>
              <a @click="downloads(appointment.id, appointment.treatment_file4, appointment.appoint_date,appointment.treatment_file_txt4,partner.name, 4, true)"
                 style="text-align: center">
                <feather-icon icon="FolderIcon" size="100"/>
                <br> <span style="text-align: center">{{ appointment.treatment_file_txt4 }} </span>
                <br>
                <feather-icon icon="DownloadIcon" style="color: black" size="20"/>
                Descargar Archivo </a>
            </b-col>
          </b-row>

          <b-row class="mt-3" v-if="getUser.department_id === 6 && place_active">
            <b-col cols="4">

            </b-col>
            <b-col cols="6">
              <b-row v-if="!suspendChemo">
                <b-col cols="4">
                  <b-form-checkbox
                      v-if="!validate_appointment"
                      v-model="validate_medical"
                      class="custom-control-primary"
                  >
                    Validar
                  </b-form-checkbox>
                  <b-form-checkbox
                      v-else
                      :disabled="validate_medical"
                      v-model="validate_medical"
                      class="custom-control-primary"
                  >
                    Tratamiento Validado
                  </b-form-checkbox>
                </b-col>
                <b-col cols="4">
                  <b-button v-if="!validate_appointment"
                            @click="validateAppointmentButton"
                            style="width: 200px"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="primary"
                            class="mr-1"
                  >
                    Validar Tratamiento
                  </b-button>
                </b-col>
              </b-row>
            </b-col>

          </b-row>
          <b-row>
            <b-col cols="6">
            </b-col>
            <b-col cols="6">
              <div align="right">
                <b-button v-if="getUser.department_id === 6 && place_active"
                          @click="deleteAppointmentUpComponent"
                          style="width: 200px"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                >
                  Eliminar Cita
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

      </b-form>
    </div>
    <div v-else>

      <div class="p-2">
        <h2>Cita de: {{ partner.name }}</h2>
      </div>
      <div class="p-2" align="center">
        <b-button
            @click="check_exit = false"
            style="width: 200px"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            class="mr-1"
        >
          Regresar
        </b-button>
      </div>
      <b-row class="p-2">
        <b-col cols="6">
          <div v-if="!check_nurse">
            <b-form-checkbox
                v-if="getUser.HrDepartment.id === 10 || getUser.HrDepartment.id === 6"
                class="custom-control-primary"
                v-model="nurse.check_nurse"
                :disabled="false"
            >
              1. Enfermeria
            </b-form-checkbox>
            <b-form-checkbox
                v-else
                class="custom-control-primary"
                v-model="nurse.check_nurse"
                :disabled="true"
            >
              1. Enfermeria
            </b-form-checkbox>
            <label for="textarea-default">Comentarios</label>
            <b-form-textarea
                v-if="getUser.HrDepartment.id === 10 || getUser.HrDepartment.id === 6"
                placeholder="Comentarios"
                v-model="nurse.comments_nurse"
                :readonly="false"
                rows="3"
            />
            <b-form-textarea
                v-else
                placeholder="Comentarios"
                v-model="nurse.comments_nurse"
                :readonly="true"
                rows="3"
            />
            <b-button v-if="getUser.HrDepartment.id === 10 || getUser.HrDepartment.id === 6"
                      @click="validate_check_exist ? updateForm('nurse') : sendForm('nurse')"
                      style="width: 200px"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1 mt-2"
            >
              Guardar
            </b-button>
          </div>
          <div v-else>
            <b-form-checkbox
                class="custom-control-primary"
                v-model="nurse.check_nurse"
                disabled=""
            >
              1. Enfermeria
            </b-form-checkbox>
            <label for="textarea-default">Comentarios</label>
            <b-form-textarea
                placeholder="Comentarios"
                v-model="nurse.comments_nurse"
                rows="3"
                readonly=""
            />

          </div>
        </b-col>
        <b-col cols="6" v-if="validate_check_exist">
          <div v-if="!check_pharmacy_register">
            <b-form-checkbox
                class="custom-control-primary"
                v-model="pharmacy.check_pharmacy"
                :disabled="getUser.HrDepartment.id !== 11 ? true : false"
            >
              2. Farmacia
            </b-form-checkbox>
            <label for="textarea-default">Comentarios</label>
            <b-form-textarea
                v-model="pharmacy.comments_pharmacy"
                placeholder="Comentarios"
                :readonly="getUser.HrDepartment.id !== 11 ? true : false"

                rows="3"
            />
            <b-button v-if="getUser.HrDepartment.id === 11 && nurse.check_nurse"
                      @click="updateForm('pharmacy')"
                      style="width: 200px"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1 mt-2"
            >
              Guardar
            </b-button>
          </div>
          <div v-else>
            <b-form-checkbox
                class="custom-control-primary"
                v-model="pharmacy.check_pharmacy"
                disabled="disabled"
            >
              2. Farmacia
            </b-form-checkbox>
            <label for="textarea-default">Comentarios</label>
            <b-form-textarea
                v-model="pharmacy.comments_pharmacy"
                placeholder="Comentarios"
                rows="3"
                readonly=""
            />
          </div>
        </b-col>
        <b-col cols="6" class="mt-3" v-if="validate_check_exist">
          <div v-if="!check_register">
            <b-form-checkbox
                class="custom-control-primary"
                v-model="cash_register.check_cash_register"
                :disabled="getUser.HrDepartment.id !== 19 ? true : false"
            >
              3. Cajas
            </b-form-checkbox>
            <label for="textarea-default">Comentarios</label>
            <b-form-textarea
                v-model="cash_register.comments_cash_register"
                placeholder="Comentarios"
                rows="3"
                :readonly="getUser.HrDepartment.id !== 19 ? true : false"
            />
            <b-button v-if="getUser.HrDepartment.id === 19 && pharmacy.check_pharmacy"
                      style="width: 200px"
                      @click="updateForm('cash_register')"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mr-1 mt-2"
            >
              Guardar
            </b-button>
          </div>
          <div v-else>
            <b-form-checkbox
                class="custom-control-primary"
                v-model="cash_register.check_cash_register"
                disabled="disabled"
            >
              3. Cajas
            </b-form-checkbox>
            <label for="textarea-default">Comentarios</label>
            <b-form-textarea
                v-model="cash_register.comments_cash_register"
                placeholder="Comentarios"
                readonly=""
                rows="3"
            />
          </div>

        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BForm, BOverlay, BSidebar, BFormCheckbox, BFormInvalidFeedback, BFormSelect, BFormFile

} from 'bootstrap-vue'
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive";
import flatPickr from 'vue-flatpickr-component'
import {mapGetters, mapActions} from "vuex";
import {VueAutosuggest} from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {email, required, url} from "@core/utils/validations/validations";
import {createTimeReal} from "@/sockets/socket";

export default {
  name: "ModalAppointment",
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver, VueAutosuggest, BFormSelect, BOverlay, BFormFile,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCard,
    BLink,
    BRow,
    BCol, DatePicker
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      reasons:[],
      reason_appoinment: {id: null, value: "Seleccione una razón"},
      check_nurse: false,
      verified_treatment_medicals: false,
      suspendChemo: false,
      check_nurse_query: false,
      check_pharmacy_register: false,
      check_register: false,
      nurse: {
        check_nurse: false,
        validated_nurse: null,
        comments_nurse: null,
        clinic_treatment_id: null,
      },
      pharmacy: {
        check_pharmacy: false,
        validated_pharmacy: null,
        comments_pharmacy: null,
        type: null
      },
      cash_register: {
        check_cash_register: false,
        validated_cash_register: null,
        comments_cash_register: null,
        type: null
      },
      validate_check_exist: false,
      verified_treatment: false,
      check_exit: false,
      validate_appointment: false,
      validate_medical: false,
      verified_treatment2: false,
      verified_treatment3: false,
      verified_treatment4: false,
      verified_treatment_edit: false,
      verified_treatment_edit2: false,
      verified_treatment_edit3: false,
      verified_treatment_edit4: false,
      new_file: false,
      new_file2: false,
      new_file3: false,
      new_file4: false,
      treatment_id: null,
      select_medical: {id: '', value: ''},
      select_group: {id: '', value: ''},
      select_price_list: {id: '', value: ''},
      sources: [{value: '', text: 'Seleccione una opción'},
      ],
      patients: [],
      value2: [],
      sidebar_status: false,
      checkCompleteStatus: false,
      show: false,
      groups: [],
      pricelists: [],
      medicals: [],
      available_appointent: true,
      place_active: false,
      types: [],
      select_types: {id: '', value: ''},
      clinical_states: [
        {value: 'p', text: 'Programa'},
        {value: 'r1', text: 'Revisión coordinador médico'},
        {value: 'r2', text: 'Revisión enfermería'},
        {value: 'rc', text: 'Revisión Conjunta'},
        {value: 'e', text: 'Envío de aseguradora/ cotizaciones'},
        {value: 'f', text: 'Farmacia'},
      ],
      partner: {},
      disabledBefore: '',
      selectedStates: ['new','approved','pending'],
      dataStates: ['new','approved','rejected','pending'],
      CheckAppointment: false,
      appointment: {
        id: null,
        customer: '',
        appoint_person_id: '',
        clinic_type_appointment_id: null,
        description: '',
        app_dt_start: '',
        app_dt_stop: '',
        appoint_date: '',
        appoint_state: 'new',
        appoint_state_clinical: "p",
        appoint_group_id: '',
        time_slot: '',
        time_slot_day: '',
        reason_appointment: null,
        source: '',
        treatment_file_txt: null,
        treatment_file_txt2: null,
        treatment_file_txt3: null,
        treatment_file_txt4: null,
        treatment_file: null,
        treatment_file2: null,
        treatment_file3: null,
        treatment_file4: null,
        paxman: false,
        pago_directo: false,
        reiki: false,
        functional_therapy: false,
        cycle: null,
        CheckAppointment: {
          check_quoted: null,
          quoted_verify_user_id: null,
          date_quoted: null,
          check_assortment_pharmacy: null,
          assortment_pharmacy_verify_user_id: null,
          date_assortment_pharmacy: null,
          check_confirmed_patient: null,
          confirmed_patient_verify_user_id: null,
          date_confirmed_patient: null,
          check_in: null,
          check_in_verify_user_id: null,
          date_check_in: null,
        }
      },
      required,
      email,
      url,
    }
  },
  props: {
    id_appoinment: Number,
    modal_close: Function

  },


  async created() {
    const day = moment().subtract(1, 'day').format('DD-MM-YYYY')
    this.disabledBefore = this.$moment(day, 'DD-MM-YYYY')
    this.sources = []
    this.patients = []
    this.appointment = {}
    this.verified_treatment = false
    this.verified_treatment_edit = false
    this.new_file = false
    this.appointment.customer = ''
    this.select_medical = {id: '', value: ''}
    this.select_group = {id: '', value: ''}
    this.select_price_list = {id: '', value: ''}
    this.appointment.id = this.id_appoinment
    await this.verifyAppointment(this.appointment.id)
    await this.verifyTreatment(this.appointment.id)
    await this.allReasons()
    await this.getType()


  },
  methods: {
    ...mapActions('patient', ['findPatientsSearchSelect']),
    ...mapActions('treatment', ['verifyCheck', 'sendCheck', 'finById', 'updateCheck', 'validateCount', 'finByIdCheck']),
    ...mapActions('calendarStoreModule', ['fetchEvents']),
    ...mapActions('appointment', ['findAppointmentDoctor', 'findAppointmentPriceList', 'findAppointmentGroups', 'appointmentAvailable',
      'findSlotVerify', 'createAppointmentSend', 'findAppointmentSource', 'deleteAppointmentUp',
      'findAppointmentUserOne', 'findApointeeSearch', 'downloadFileAppointment', 'deleteFile', 'downloadFileAppointmentAll', 'deleteFileAll', 'validateAppointment', 'getTypeAppointment', 'createCheckAppointment',
      'updateStateAppointment','appointmentAvailableNot','appointmentAvailableAndId','appointmentAvailablePaxmanAndId','getListAppointment']),
    async verifyTreatment(id) {
      const response_treatment = await this.finById(id)
      this.checkCompleteStatus = false;
      if (response_treatment){
        if (response_treatment.check_exit){
          this.checkCompleteStatus = true;
        }
      }
    },
    async changeStatusAppointment(id, state){
      if (state === 'approved'){
        const responseApproved = await this.verifyDate('approved');
        if (responseApproved){
          return
        }
      }

      this.show = true;
      this.$swal({
        title: 'Desea continuar?',
        text: "Seguro que quieres cambiar de estado la cita",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response = await this.updateStateAppointment({id: id, state: state});
          if (response) {
            this.$swal({
              icon: 'success',
              title: 'Editado!',
              text: 'Su cita Cambio de estado correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.show = false
            await this.verifyAppointment(id);
            await createTimeReal(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al editar esquema',
                icon: 'error',
                variant: 'warning',
              },
            });
            this.show = false
          }


        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })

    },

    async allReasons(){
      const response = await this.getListAppointment();
      this.reasons = []
      for (const res of response){
        this.reasons.push({
          id:res.value,
          value:res.value,
        })
      }
      const searchReason = response.find((a)=> a.value === this.appointment.reason_appointment )
      if (searchReason){
        this.reason_appoinment.id = searchReason.value;
        this.reason_appoinment.value = searchReason.value;
      }

    },
    async changeReason(){
      this.appointment.reason_appointment = this.reason_appoinment.id;
    },
    async verifyState(id) {

      const response_treatment = await this.finById(id)

      if (!response_treatment) {
        this.check_exit = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El tratamiento no ha sido concluido`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      const response_treatment_check = await this.finByIdCheck(id)
      if (!response_treatment_check) {
        this.check_exit = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `El tratamiento no ha sido concluido`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.treatment_id = response_treatment.id
      const response = await this.verifyCheck(this.treatment_id);
      if (response) {
        this.validate_check_exist = true
        this.nurse.check_nurse = response.check_nurse
        this.check_nurse = response.check_nurse
        this.check_register = response.check_cash_register
        this.nurse.comments_nurse = response.comments_nurse
        this.pharmacy.check_pharmacy = response.check_pharmacy
        this.check_pharmacy_register = response.check_pharmacy ? response.check_pharmacy : false
        this.check_register = response.comments_cash_register ? response.comments_cash_register : false
        this.pharmacy.comments_pharmacy = response.comments_pharmacy
        this.cash_register.comments_cash_register = response.comments_cash_register
        this.cash_register.check_cash_register = response.check_cash_register
      } else {
        this.validate_check_exist = false
      }
      this.check_exit = true
    },
    async sendForm(state) {
      if (state === 'nurse') {
        this.nurse.validated_nurse = this.getUser.id
        this.nurse.clinic_treatment_id = this.treatment_id
        if (!this.nurse.check_nurse) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `El checkbox es requerido`,
              icon: 'warning',
              variant: 'error',
            },
          })
          return
        }
        const response_nurse = await this.sendCheck(this.nurse)
        if (response_nurse) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Se valido el tratamiento correctamente`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          await this.verifyState(this.appointment.id)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `error al validar consulte con el administrador`,
              icon: 'EditIcon',
              variant: 'error',
            },
          })
        }
      }
      await this.validateCount()
    },
    async typesVerify() {
      this.appointment.clinic_type_appointment_id = this.select_types.id;
    },
    async getType() {

      this.pricelists = []
      const response = await this.getTypeAppointment()
      for (const a of response) {
        this.types.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async updateForm(state) {
      if (state === 'pharmacy') {
        this.pharmacy.validated_pharmacy = this.getUser.id
        this.pharmacy.type = state
        if (!this.pharmacy.check_pharmacy) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `El checkbox es requerido`,
              icon: 'warning',
              variant: 'error',
            },
          })
          return
        }
        const response_pharmacy = await this.updateCheck({id: this.treatment_id, data: this.pharmacy})
        if (response_pharmacy) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Se valido el tratamiento correctamente`,
              icon: 'warning',
              variant: 'success',
            },
          })
          await this.verifyState(this.appointment.id)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `error al validar consulte con el administrador`,
              icon: 'warning',
              variant: 'error',
            },
          })
        }
      } else if (state === 'cash_register') {
        this.cash_register.validated_cash_register = this.getUser.id
        this.cash_register.type = state
        if (!this.cash_register.check_cash_register) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `El checkbox es requerido`,
              icon: 'warning',
              variant: 'error',
            },
          })
          return
        }
        const response_pharmacy = await this.updateCheck({id: this.treatment_id, data: this.cash_register})
        if (response_pharmacy) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Se valido el tratamiento correctamente`,
              icon: 'warning',
              variant: 'success',
            },
          })
          await this.verifyState(this.appointment.id)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `error al validar consulte con el administrador`,
              icon: 'warning',
              variant: 'error',
            },
          })
        }
      } else {
        this.nurse.validated_nurse = this.getUser.id
        this.nurse.clinic_treatment_id = this.treatment_id
        this.nurse.type = state
        if (!this.nurse.check_nurse) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `El checkbox es requerido`,
              icon: 'warning',
              variant: 'error',
            },
          })
          return
        }
        const response_nurse = await this.updateCheck({id: this.treatment_id, data: this.nurse})
        if (response_nurse) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Se valido el tratamiento correctamente`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          await this.verifyState(this.appointment.id)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `error al validar consulte con el administrador`,
              icon: 'EditIcon',
              variant: 'error',
            },
          })
        }
      }
      await this.validateCount();
      await createTimeReal(true)
    },
    async handleImage(id) {
      const file = document.querySelector(`#file${id}`).files[0]
      const file_convert = await this.getBase64(file)
      file_convert.onload = (event) => {
        if (id === 1) {
          this.appointment.treatment_file = event.target.result
          this.appointment.treatment_file_txt = file.name
        } else if (id === 2) {
          this.appointment.treatment_file2 = event.target.result
          this.appointment.treatment_file_txt2 = file.name
        } else if (id === 3) {
          this.appointment.treatment_file3 = event.target.result
          this.appointment.treatment_file_txt3 = file.name
        } else {
          this.appointment.treatment_file4 = event.target.result
          this.appointment.treatment_file_txt4 = file.name
        }

        /* this.company.file_key = event.target.result.split(',')[1]*/
      };
    },
    async validateAppointmentButton() {
      if (!this.validate_medical) {
        return
      } else {
        this.show = true
        const response = await this.validateAppointment(this.appointment.id)
        if (response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Se valido el tratamiento correctamente`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          await createTimeReal(true)
          this.show = false
          await this.$root.$emit('closeComponent')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `error al validar consulte con el administrador`,
              icon: 'EditIcon',
              variant: 'error',
            },
          })
        }
      }

    },
    async deleteAppointmentUpComponent() {

      this.show = true
      const response = await this.deleteAppointmentUp(this.appointment.id)
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se elimino el tratamiento correctamente`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        await createTimeReal(true);
        await this.$root.$emit('closeComponent')


      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `error al eliminar consulte con el administrador`,
            icon: 'EditIcon',
            variant: 'error',
          },
        })
      }

    },
    async changeDate() {
      this.appointment.app_dt_start = moment(this.value2[0]).format('YYYY-MM-DD HH:mm:ss')
      this.appointment.app_dt_stop = moment(this.value2[1]).format('YYYY-MM-DD HH:mm:ss')
      await this.verifyDate('')
    },
    async deleteFileAppointment(id, file_number) {
      const send = {
        id, file_number
      }
      const response = await this.deleteFileAll(send)
      this.show = true
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se borro el archivo`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        if (parseInt(file_number) === 1) {
          this.appointment.treatment_file = null
          this.appointment.treatment_file_txt = null
          this.new_file = true
          this.verified_treatment = false
        } else if (parseInt(file_number) === 2) {
          this.appointment.treatment_file2 = null
          this.appointment.treatment_file_txt2 = null
          this.new_file2 = true
          this.verified_treatment2 = false
        } else if (parseInt(file_number) === 3) {
          this.appointment.treatment_file3 = null
          this.appointment.treatment_file_txt3 = null
          this.new_file3 = true
          this.verified_treatment3 = false
        } else {
          this.appointment.treatment_file4 = null
          this.appointment.treatment_file_txt4 = null
          this.new_file4 = true
          this.verified_treatment4 = false
        }

      } else {

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al borrar el archivo',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      }
      this.show = false
    },
    async downloads(id, file, name, name_file, partner, file_number, download) {

      const [, type] = file.split(';')[0].split('/');
      const send = {
        id,
        type,
        name,
        partner,
        file_number,
        name_file,
        download
      }
      await this.downloadFileAppointmentAll(send)
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async verifySource() {
      let name_source;
      for (const a of this.sources) {
        if (a.value === this.appointment.source) {
          name_source = a.text
        }
      }
      if (name_source === 'Tratamiento') {
        this.verified_treatment_medicals = true
        let new_group = []
        const response = await this.findAppointmentGroups()
        for (const a of response) {
          if (a.is_place) {
            this.select_group = {id: a.id, value: a.group_name}
            new_group.push({
              id: a.id,
              value: a.group_name,
              verifed: a.is_place
            })
          }
        }
        this.groups = []
        this.groups = new_group
        await this.searchGroup(this.appointment.appoint_group_id)
        this.place_active = true
        this.verified_treatment = true
        const search = this.groups.find((a) => a.id === this.appointment.appoint_group_id)
        this.select_group.id = search.id;
        this.select_group.value = search.value;

      } else {
        let new_group = []
        this.medicals = []
        this.appointment.treatment_file = null
        this.select_group = {id: '', value: ''}
        const response = await this.findAppointmentGroups()
        for (const a of response) {
          if (!a.is_place) {
            new_group.push({
              id: a.id,
              value: a.group_name,
              verifed: a.is_place
            })
          }

        }
        this.verified_treatment = false
        this.groups = []
        this.groups = new_group
        this.place_active = false
      }
    },
    async searchGroup(id) {
      const response = await this.findApointeeSearch(id);
      this.medicals = []
      for (const a of response) {
        this.medicals.push({
          id: a.ResPartnerAppointment.id,
          value: a.ResPartnerAppointment.name,
        })
      }

    },
    async verifyAppointment(id) {
      const response = await this.findAppointmentUserOne(id);

      if (response.AppointmentSource.name === 'Consulta') {
        this.sources.push({
          value: response.AppointmentSource.id, text: response.AppointmentSource.name
        })
        const dat1 = this.resHoursOdoo(response.app_dt_start)
        const dat2 = this.resHoursOdoo(response.app_dt_stop)
        this.appointment = response
        this.value2.push(new Date(dat1))
        this.value2.push(new Date(dat2))
        this.appointment.app_dt_start = dat1
        this.appointment.app_dt_stop = dat2

        this.validate_medical = response.validate_appointment
        if (response.ClinicTypeAppointment) {
          this.select_types.id = response.ClinicTypeAppointment.id
          this.select_types.value = response.ClinicTypeAppointment.name
          this.appointment.clinic_type_appointment_id = response.ClinicTypeAppointment.id
        }
        if (response.validate_appointment) {
          this.validate_appointment = response.validate_appointment
        } else {
          this.validate_appointment = response.validate_appointment
        }
        this.appointment.source = response.source

        this.partner = response.ResPartner;
        this.select_medical.id = response.ResPartnerAppointment.id
        this.select_medical.value = response.ResPartnerAppointment.name
        this.select_group.id = response.AppointmentGroup.id
        this.select_group.value = response.AppointmentGroup.group_name
        await this.verifySource()
        if (response.AppointmentGroup.is_place) {
          if (response.treatment_file) {
            this.verified_treatment = true
          } else {

            this.new_file = true
            this.verified_treatment = false
          }
          if (response.treatment_file2) {
            this.verified_treatment2 = true
          } else {
            this.new_file2 = true
            this.verified_treatment2 = false
          }
          if (response.treatment_file3) {
            this.verified_treatment3 = true
          } else {
            this.new_file3 = true
            this.verified_treatment3 = false
          }
          if (response.treatment_file4) {
            this.verified_treatment4 = true
          } else {
            this.new_file4 = true
            this.verified_treatment4 = false
          }
        }

        if (response.ResPartner.chemo_suspension) {
          this.available_appointent = false;
          this.suspendChemo = true
          this.$swal({
            icon: 'warning',
            title: `Quimioterapia supendida:  ${response.ResPartner.reason_suspension} `,
            text: `El paciente esta suspendido por: ${response.ResPartner.comments_suspension ?  response.ResPartner.comments_suspension:'Quimioterapia Suspendida!'} `,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })

        } else {
          this.suspendChemo = false
          this.available_appointent = true
        }
        delete this.appointment.ResPartnerAppointment
        delete this.appointment.ResPartner
        delete this.appointment.ProductPricelist
        delete this.appointment.AppointmentGroup
        delete this.appointment.AppointmentSource
        delete this.appointment.ClinicTypeAppointment

        return
      } else {
        if (response.CheckAppointment) {
          this.sources.push({
            value: response.AppointmentSource.id, text: response.AppointmentSource.name
          })
          const dat1 = this.resHoursOdoo(response.app_dt_start)
          const dat2 = this.resHoursOdoo(response.app_dt_stop)
          delete response.CheckAppointment.appointment_id
          this.appointment = response
          this.CheckAppointment = true
          this.value2.push(new Date(dat1))
          this.value2.push(new Date(dat2))
          this.appointment.app_dt_start = dat1
          this.appointment.app_dt_stop = dat2
          this.validate_medical = response.validate_appointment
          if (response.ClinicTypeAppointment) {
            this.select_types.id = response.ClinicTypeAppointment.id
            this.select_types.value = response.ClinicTypeAppointment.name
            this.appointment.clinic_type_appointment_id = response.ClinicTypeAppointment.id
          }
          if (response.validate_appointment) {
            this.validate_appointment = response.validate_appointment
          } else {
            this.validate_appointment = response.validate_appointment
          }
          this.appointment.source = response.source
          this.partner = response.ResPartner;
          this.select_medical.id = response.ResPartnerAppointment.id
          this.select_medical.value = response.ResPartnerAppointment.name
          this.select_group.id = response.AppointmentGroup.id
          this.select_group.value = response.AppointmentGroup.group_name
          await this.verifySource()
          if (response.AppointmentGroup.is_place) {
            if (response.treatment_file) {
              this.verified_treatment = true
            } else {

              this.new_file = true
              this.verified_treatment = false
            }
            if (response.treatment_file2) {
              this.verified_treatment2 = true
            } else {
              this.new_file2 = true
              this.verified_treatment2 = false
            }
            if (response.treatment_file3) {
              this.verified_treatment3 = true
            } else {
              this.new_file3 = true
              this.verified_treatment3 = false
            }
            if (response.treatment_file4) {
              this.verified_treatment4 = true
            } else {
              this.new_file4 = true
              this.verified_treatment4 = false
            }
          }
          if (response.ResPartner.chemo_suspension) {
            this.available_appointent = false;
            this.suspendChemo = true
            this.$swal({
              icon: 'warning',
              title: `Quimioterapia supendida:  ${response.ResPartner.reason_suspension} `,
              text: `El paciente esta suspendido por: ${response.ResPartner.comments_suspension ?  response.ResPartner.comments_suspension:'Quimioterapia Suspendida!'} `,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          } else {
            this.available_appointent = true
            this.suspendChemo = false
          }
          delete this.appointment.ResPartnerAppointment
          delete this.appointment.ResPartner
          delete this.appointment.ProductPricelist
          delete this.appointment.AppointmentGroup
          delete this.appointment.AppointmentSource
          delete this.appointment.ClinicTypeAppointment
        } else {
          await this.createCheckAppointment(id)
          await this.verifyAppointment(id)

        }

      }

    },
    async verifyDate(type = null) {
      if (this.appointment.app_dt_start) {
        const separate = this.appointment.app_dt_start.split(' ')
        this.appointment.appoint_date = separate[0]
        this.appointment.time_slot_day = this.dateString(separate[0])
        this.appointment.time_slot = await this.getSlot(this.appointment.time_slot_day)
        if (!this.appointment.time_slot) {
          this.available_appointent = false
          return
        }
        if (this.appointment.app_dt_start && this.appointment.app_dt_stop && this.appointment.appoint_person_id) {

          if(this.appointment.paxman){
            const verifyPaxman = await this.medicalVerifyPaxman();
            if (!verifyPaxman){
              return
            }
          }
          if (type){
            const responseVerify = await this.medicalVerifyApproved();
            return responseVerify;
          }else{
            await this.medicalVerify()
          }


        }
      }

    },
    async getSlot(day) {
      if (day) {
        const response = await this.findSlotVerify(day.toLowerCase());
        if (response) {
          this.available_appointent = true
          return response
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Este dia no esta disponible',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          this.available_appointent = false
          return ''
        }
      }


    },
    async createAppointment(appointment) {
      if (this.appointment.appoint_group_id !== 1) {
        const responseAppointment = await this.findAppointmentUserOne(this.appointment.id);
        if (this.appointment.CheckAppointment.check_quoted !== responseAppointment.CheckAppointment.check_quoted) {
          this.appointment.CheckAppointment.quoted_verify_user_id = this.getUser.id;
          this.appointment.CheckAppointment.date_quoted = this.sumHoursOdooV(new Date());
        } else {
          this.appointment.CheckAppointment.quoted_verify_user_id = responseAppointment.CheckAppointment.quoted_verify_user_id
          this.appointment.CheckAppointment.date_quoted = responseAppointment.CheckAppointment.date_quoted
        }
        if (this.appointment.CheckAppointment.check_assortment_pharmacy !== responseAppointment.CheckAppointment.check_assortment_pharmacy) {
          this.appointment.CheckAppointment.assortment_pharmacy_verify_user_id = this.getUser.id
          this.appointment.CheckAppointment.date_assortment_pharmacy = this.sumHoursOdooV(new Date())
        } else {
          this.appointment.CheckAppointment.assortment_pharmacy_verify_user_id = responseAppointment.CheckAppointment.assortment_pharmacy_verify_user_id
          this.appointment.CheckAppointment.date_assortment_pharmacy = responseAppointment.CheckAppointment.date_assortment_pharmacy
        }
        if (this.appointment.CheckAppointment.check_confirmed_patient !== responseAppointment.CheckAppointment.check_confirmed_patient) {
          this.appointment.CheckAppointment.date_confirmed_patient = this.sumHoursOdooV(new Date())
          this.appointment.CheckAppointment.confirmed_patient_verify_user_id = this.getUser.id

        } else {
          this.appointment.CheckAppointment.confirmed_patient_verify_user_id = responseAppointment.CheckAppointment.confirmed_patient_verify_user_id
          this.appointment.CheckAppointment.date_confirmed_patient = responseAppointment.CheckAppointment.date_confirmed_patient
        }
        if (this.appointment.CheckAppointment.check_in !== responseAppointment.CheckAppointment.check_in) {
          this.appointment.CheckAppointment.check_in_verify_user_id = this.getUser.id
          this.appointment.CheckAppointment.date_check_in = this.sumHoursOdooV(new Date())

        } else {
          this.appointment.CheckAppointment.date_check_in = responseAppointment.CheckAppointment.date_check_in
          this.appointment.CheckAppointment.check_in_verify_user_id = responseAppointment.CheckAppointment.check_in_verify_user_id
        }
      }


      let res;
      let res_error;
      if (appointment === 'create') {
        res = 'creada'
        res_error = 'crear'
      } else {
        res = 'editada'
        res_error = 'editar'
      }
      this.show = true
      if (this.appointment.paxman === ''){
        this.appointment.paxman = true
      }
      if (this.appointment.pago_directo === ''){
        this.appointment.pago_directo = true
      }
      if (this.appointment.reiki === ''){
        this.appointment.reiki = true
      }
      if (this.appointment.functional_therapy === ''){
        this.appointment.functional_therapy = true
      }
      const response = await this.createAppointmentSend(this.appointment)
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Cita ${res}`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        this.CheckAppointment = false
        this.appointment = {}
        this.partner = {}
        if (!this.place_active) {
          await this.$root.$emit('closeComponent')
          await this.modal_close
        } else {
          await this.$root.$emit('closeComponent')
          await this.modal_close
        }
        await createTimeReal(true)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error al ${res_error} cita`,
            icon: 'error',
            variant: 'warning',
          },
        });
        this.show = false

      }
    },

    async patientVerify(item) {
      this.appointment.customer = item.item.id
    },
    async getPatientSelect(text) {

      if (text === '' || text === undefined) {
        this.patients = []
        return
      }
      this.patients = []
      let cou = []
      const response = await this.findPatientsSearchSelect(text);
      for (const a of response) {
        cou.push({
          id: a.id,
          name: a.name,
        })
      }
      this.patients = [{
        data: cou,
      }]

    },
    async medicalVerifyPaxman() {
      if (this.appointment.paxman === '' || this.appointment.paxman) {
        if (this.appointment.app_dt_start && this.appointment.app_dt_stop) {

          const send = {
            start: this.sumHoursOdoo(this.appointment.app_dt_start),
            end: this.sumHoursOdoo(this.appointment.app_dt_stop),
            id: this.appointment.id,
          }
          const response = await this.appointmentAvailablePaxmanAndId(send);

          if (!response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `No hay citas en esta Fecha para paxman`,
                icon: 'EditIcon',
                variant: 'warning',
              },
            })

            this.available_appointent = response
            return response
          } else {

            this.available_appointent = response
            return response
          }
        }
      }else{
        this.available_appointent = true
        return true
      }
    },
    async medicalVerify() {
      this.appointment.appoint_person_id = this.select_medical.id;
      if (this.appointment.app_dt_start && this.appointment.app_dt_stop) {

        const send = {
          start: this.sumHoursOdoo(this.appointment.app_dt_start),
          end: this.sumHoursOdoo(this.appointment.app_dt_stop),
          medical_id: this.select_medical.id,
        }
        const response = await this.appointmentAvailable(send);

        if (!response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `No hay citas en esta Fecha`,
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          this.available_appointent = response;
        } else {
          this.available_appointent = response;
        }
      }
    },
    async medicalVerifyApproved() {
      this.appointment.appoint_person_id = this.select_medical.id;
      if (this.appointment.app_dt_start && this.appointment.app_dt_stop) {

        const send = {
          start: this.sumHoursOdoo(this.appointment.app_dt_start),
          end: this.sumHoursOdoo(this.appointment.app_dt_stop),
          medical_id: this.select_medical.id,
          id: this.appointment.id,
        }
        const response = await this.appointmentAvailableNot(send);
        if (!response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `No puede aprobar la cita por que hay otra cita en este horario`,
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return true;
        } else {
          return false;
        }
      }
    },
    async groupsVerify() {
      this.appointment.appoint_group_id = this.select_group.id;
      await this.searchGroup(this.select_group.id)
    },
    async getGroupSelect() {
      this.groups = []
      const response = await this.findAppointmentGroups()
      for (const a of response) {
        this.groups.push({
          id: a.id,
          value: a.group_name,
          verifed: a.is_place
        })
      }
    },
    async getMedicalSelect() {
      this.medicals = []
      const response = await this.findAppointmentDoctor()
      for (const a of response) {
        this.medicals.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async getSource() {
      const response = await this.findAppointmentSource()
      for (const a of response) {
        this.sources.push({
          value: a.id,
          text: a.name,
        })
      }
    },
    async getPriceListSelect() {

      this.pricelists = []
      const response = await this.findAppointmentPriceList()
      for (const a of response) {
        this.pricelists.push({
          id: a.id,
          value: a.name,
        })
      }
    }
  }
  ,
  computed: {
    ...
        mapGetters('auth', ['getUser']),
  }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
