import io from "socket.io-client";
//const urlSocket = 'localhost:3007'
const urlSocket = "desk.helenms.onkimia.com.mx";
export const createTimeReal = async (status) => {
  io(urlSocket).emit("TREATMENT_EDIT", {
    status: status,
  });
};

export { urlSocket };
